import React from 'react'
import Header from "../components/header.js"
import Footer from "../components/footer.js"
import Panel from "../components/panel.js"

const post = "<h1>404 Error</h1>\
<p>The page you're looking for doesn't exist! Not yet, anyway...</p>"

const NotFoundPage = () => (
  <div>
    <Header />
    <Panel post={{__html: post}} />
    <Footer />
  </div>
)
  
export default NotFoundPage
